import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Nav from '../components/Nav/Nav'

const CalendarPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Nav />
    
    <h3>Calendar / Events</h3>

    <iframe
      src="https://calendar.google.com/calendar/embed?src=1sfhh7jb40fjjrb6h5db12oaro%40group.calendar.google.com&ctz=America%2FLos_Angeles"
      width="100%"
      height="800"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="calendar"
    >
      Loading…
    </iframe>
  </Layout>
)

export default CalendarPage
